'use strict';

angular.module('mvpcarrefourApp', [
    'mvpcarrefourApp.auth',
    'mvpcarrefourApp.admin',
    'mvpcarrefourApp.constants',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'btford.socket-io',
    'ui.router',
    'validation.match',
    'ui.bootstrap',
    'ngFileUpload',
    'ngGridPanel',
    'angulartics',
    'angulartics.google.tagmanager',
    'infinite-scroll',
    'angularLazyImg'
])

.config(function($urlRouterProvider, $locationProvider) {
        $urlRouterProvider
            .otherwise('/');

        $urlRouterProvider.rule(function($injector, $location) {

            var path = $location.path();
            var hasTrailingSlash = path[path.length - 1] === '/';

            if (hasTrailingSlash) {

                //if last charcter is a slash, return the same url without the slash
                var newPath = path.substr(0, path.length - 1);
                return newPath;
            }

        });

        $locationProvider.html5Mode(true);
    })
    .run(function($rootScope, $state, Auth, Vote) {

        $rootScope.$state = $state;
        $rootScope._ = _;

        $rootScope.votes = [];
        $rootScope.currentUser = Auth.getCurrentUser() ? Auth.getCurrentUser() : {};

        //Posiciona al top con los cambios de pagina
        $rootScope.$on("$locationChangeSuccess", function(event) {
            if (event.targetScope.$state.current.name !== "main.vote.category.product")
                document.body.scrollTop = document.documentElement.scrollTop = 0;
        });

    });
